import axios from '@/api/http'

export function submitVoice (audioParams) {
  const params = new FormData()
  params.append('app_id', audioParams.appId)
  params.append('format', audioParams.format)
  params.append('rate', audioParams.rate)
  params.append('speech', audioParams.speech)
  params.append('time_stamp', audioParams.timestamp)
  params.append('nonce_str', audioParams.nonceStr)
  params.append('sign', audioParams.sign())
  return axios.post('/fcgi-bin/aai/aai_asr', params,
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}
