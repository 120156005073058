<template>
  <div id="panorama"></div>
</template>

<script>
import { CommandArray } from '@/api/command'

export default {
  name: 'Panorama',
  props: {
    panoHooks: Object
  },
  data () {
    return {
      xml: 'xml/shanghai.xml',
      city: 'shanghai',
      krpano: null
    }
  },
  mounted () {
    this.initPanorama()
  },
  methods: {
    initPanorama () {
      window.embedpano({
        xml: this.xml,
        target: 'panorama',
        html5: 'only',
        mobilescale: 1.0,
        onready: this.panoramaReady
      })
    },
    panoramaReady (obj) {
      this.krpano = obj.get('global')
      this.krpano.hooks = this.panoHooks
    },
    commandCheck (command) {
      // 判断指令
      let valid = true
      for (const c of CommandArray) {
        valid = true
        for (const key of c.keywords) {
          console.log(key)
          if (command.indexOf(key) === -1) {
            valid = false
            break
          }
        }
        // 如果valid为true，则匹配上
        if (valid) {
          if (this.city !== c.target) {
            this.krpano.call(`change_scene(${c.target})`)
            this.city = c.target
            setTimeout(() => {
              this.krpano.call(c.exec)
            }, 1000)
          } else {
            this.krpano.call(c.exec)
          }
          break
        }
      }
      // 都未匹配，
      if (!valid) {
        alert(`无法识别：${command}`)
      }
    }
  }
}
</script>

<style scoped>
  #panorama {
    width: 100%;
    height: 100%;
  }
</style>
