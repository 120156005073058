export const CommandArray = [
  { keywords: ['换', '上海'], target: 'shanghai', exec: 'change_scene(shanghai)' },
  { keywords: ['打开', '上海'], target: 'shanghai', exec: 'change_scene(shanghai)' },
  { keywords: ['看', '外滩'], target: 'shanghai', exec: 'change_view(-24.93, 16.01, 8)' },
  { keywords: ['看', '东方明珠'], target: 'shanghai', exec: 'change_view(-2.58, 11.97, 20)' },
  { keywords: ['换', '武汉'], target: 'wuhan', exec: 'change_scene(wuhan)' },
  { keywords: ['打开', '武汉'], target: 'wuhan', exec: 'change_scene(wuhan)' },
  { keywords: ['看', '黄鹤楼'], target: 'wuhan', exec: 'change_view(-177.30, 4.69, 5)' },
  { keywords: ['看', '蛇山'], target: 'wuhan', exec: 'change_view(-149.54, 3.22, 5)' }
]
