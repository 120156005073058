import CryptoJS from 'crypto-js'

export default class AudioParams {
  constructor (timestamp, nonceStr, format, speech, rate) {
    this.appId = 2163251338
    this.timestamp = timestamp
    this.nonceStr = nonceStr
    this.format = format
    this.speech = speech
    this.rate = rate
  }

  sign () {
    const t = `app_id=${this.appId}&format=${this.format}&nonce_str=${this.nonceStr}&rate=${this.rate}&speech=${AudioParams.fixedEncodeURIComponent(this.speech)}&time_stamp=${this.timestamp}&app_key=MQDcIVbmLFueG674`
    return CryptoJS.MD5(t).toString().toUpperCase()
  }

  static fixedEncodeURIComponent (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16)
    })
  }
}
