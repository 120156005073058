<template>
  <div class="home">
    <Panorama :pano-hooks="hooks" ref="panoView" />
    <div class="anim" id="home_anim_container" v-show="show">
    </div>
    <div class="anim" id="home_wait_anim_container" v-show="waitShow">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Panorama from '@/components/Panorama.vue'
import lottie from 'lottie-web'
import Recorder from 'recorder-core/recorder.wav.min'

import AudioParams from '@/api/audio'
import { submitVoice } from '@/api/service'

export default {
  name: 'Home',
  components: {
    Panorama
  },
  data () {
    return {
      show: false,
      waitShow: false,
      firstLoad: true,
      anim: null,
      waitAnim: null,
      rec: null,
      ret: -1,
      hooks: {}
    }
  },
  created () {
    this.hooks.startRecord = this.startRecord
    this.hooks.stopRecord = this.stopRecord
  },
  mounted () {
    this.loadAnim()
    // 首次调用，在ios上将请求录音权限
    this.startRecord()
  },
  methods: {
    startRecord () {
      const recOpen = (success) => {
        this.rec = Recorder({
          type: 'wav',
          sampleRate: 16000,
          bitRate: 16,
          onProcess: () => { // buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd
            //
          }
        })
        this.rec.open(() => {
          // 授权成功
          success && success()
        }, (msg, isUserNotAllow) => {
          // 用户拒绝或不支持
          console.log(isUserNotAllow ? 'User not allow' : msg)
        })
      }
      recOpen(() => {
        if (!this.firstLoad) {
          this.show = true
          this.anim.play()
          this.rec.start()
        } else {
          this.firstLoad = false
          this.rec.close()
          this.rec = null
        }
      })
    },
    stopRecord () {
      this.show = false
      this.anim.pause()
      this.rec.stop((blob, duration) => {
        const url = this.createObjectURL(blob)
        console.log(blob, url, '时长' + duration + 'ms')
        this.rec.close()
        this.rec = null
        // 显示等待动画
        this.waitAnim.play()
        this.waitShow = true
        // 生成参数
        const timestamp = Math.round(new Date() / 1000)
        this.blobToDataURI(blob, (speech) => {
          const ap = new AudioParams(timestamp, `a${timestamp}`, 2, speech, 16000)
          submitVoice(ap).then(response => {
            console.log(response)
            const respData = response.data
            this.waitShow = false
            this.waitAnim.pause()
            if (respData.ret === 0) {
              this.execCommand(respData.data)
            }
          }).catch(e => {
            this.waitShow = false
            this.waitAnim.pause()
            console.log(e)
          })
        })
      }, (msg) => {
        // 录音失败
        console.log('录音失败' + msg)
        this.rec.close()
        this.rec = null
        alert(msg)
      })
    },
    execCommand (data) {
      // alert(data.text)
      this.$refs.panoView.commandCheck(data.text)
    },
    blobToDataURI (blob, callback) {
      const reader = new FileReader()
      reader.onload = function () {
        // e.target.result
        callback((/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result.toString()) || [])[1])
      }
      reader.readAsDataURL(blob)
    },
    createObjectURL (file) {
      if (window.webkitURL) {
        return window.webkitURL.createObjectURL(file)
      } else if (window.URL && window.URL.createObjectURL) {
        return window.URL.createObjectURL(file)
      } else {
        return null
      }
    },
    loadAnim () {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('home_anim_container'),
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/lottie/voice.json'
      })
      this.waitAnim = lottie.loadAnimation({
        container: document.getElementById('home_wait_anim_container'),
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/lottie/wait.json'
      })
    }
  }
}
</script>

<style scoped>
  .home {
    width: 100%;
    height: 100%;
  }
  .anim {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
  }
</style>
